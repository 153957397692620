@font-face {
	font-family: 'Jost';
	font-style: normal;
	font-weight: 400;
	src: url('/fonts/Jost-400-Book.ttf') format('truetype');
	src: url('/fonts/Jost-400-Book.otf') format('opentype');
}

@font-face {
	font-family: 'Jost';
	font-style: italic;
	font-weight: 400;
	src: url('/fonts/Jost-400-BookItalic.ttf') format('truetype');
	src: url('/fonts/Jost-400-BookItalic.otf') format('opentype');
}

@font-face {
	font-family: 'Jost';
	font-style: normal;
	font-weight: 500;
	src: url('/fonts/Jost-500-Medium.ttf') format('truetype');
	src: url('/fonts/Jost-500-Medium.otf') format('opentype');
}

@font-face {
	font-family: 'Jost';
	font-style: italic;
	font-weight: 500;
	src: url('/fonts/Jost-500-MediumItalic.ttf') format('truetype');
	src: url('/fonts/Jost-500-MediumItalic.otf') format('opentype');
}

@font-face {
	font-family: 'Jost';
	font-style: normal;
	font-weight: 600;
	src: url('/fonts/Jost-600-Semi.ttf') format('truetype');
	src: url('/fonts/Jost-600-Semi.otf') format('opentype');
}

@font-face {
	font-family: 'Jost';
	font-style: italic;
	font-weight: 600;
	src: url('/fonts/Jost-600-SemiItalic.ttf') format('truetype');
	src: url('/fonts/Jost-600-SemiItalic.otf') format('opentype');
}

@font-face {
	font-family: 'Jost';
	font-style: normal;
	font-weight: 700;
	src: url('/fonts/Jost-700-Bold.ttf') format('truetype');
	src: url('/fonts/Jost-700-Bold.otf') format('opentype');
}

@font-face {
	font-family: 'Jost';
	font-style: italic;
	font-weight: 700;
	src: url('/fonts/Jost-700-BoldItalic.ttf') format('truetype');
	src: url('/fonts/Jost-700-BoldItalic.otf') format('opentype');
}
