.search {
	&-header {
		background-image: linear-gradient($body-background 0%, $body-background 50%, $color-amour 100%);
		padding: 2.75rem var(--body-margin) 1.5rem;

		&-separator {
			background-color: $color-grey-85;
			display: none;
			height: 0.0625rem;
			margin-bottom: 1.25rem;
			margin-top: 3rem;

			@include breakpoint-up(lg) {
				display: block;
			}
		}

		h3 {
			color: $color-grey-35;
			margin: 2.125rem 0;
			text-align: center;

			@include breakpoint-up(lg) {
				margin-bottom: 0;
				text-align: left;
			}

			strong {
				color: $body-font-color;
			}
		}
	}

	&-block {
		background-color: $color-amour;
		display: flex;
		flex-direction: column;
		min-height: 28rem;
		padding-bottom: 2.5rem;

		@include breakpoint-up(lg) {
			padding-bottom: 3.375rem;
		}

		&-footer {
			margin: 7.5rem auto 5rem;
		}

		&-loadmore-button {
			@extend %load-more;
		}
	}

	&-most-recent {
		&-title {
			font-size: 1.375rem;
			letter-spacing: 0.0775rem;
			padding: 0 var(--body-margin);
			text-transform: uppercase;

			@include breakpoint-up(md) {
				font-size: 1.625rem;
				letter-spacing: 0.1406rem;
				margin-top: 2rem;
			}

			@include breakpoint-up(lg) {
				font-size: 1.75rem;
			}
		}

		.post-block-grid {
			@include breakpoint-up(md) {
				margin-top: 1rem;
			}
		}
	}

	.no-results {
		background-color: $color-amour;
		font-family: $body-font-family;
		font-size: 1rem;
		letter-spacing: 0.0313rem;
		margin: 0;
		padding: 3.25rem var(--body-margin) 19rem;
		text-align: center;
	}
}

.search-bar {
	&-menu,
	&-compact {
		input {
			font-family: $accent-font;
			font-size: 1rem;
			font-weight: $font-weight-medium;
			letter-spacing: 0.063rem;

			&:focus {
				box-shadow: none;
				outline-style: none;
			}
		}
	}


	&-menu {

		display: flex;
		width: 100%;

		@include breakpoint-up(xl) {
			position: absolute;
			top: -4.5rem;
			width: calc(100% - 4.5rem);
		}

		/*
			In the ComboboxSearch component, the `input` element is wrapped in a `label` element,
			so the margin-right does not affect the sibling `button` element as in the SearchBar component.
		*/
		input:not(.combobox-input),
		.combobox-search {
			border: 0;
			border-bottom: 0.125rem solid $black-bg-color;
			flex: 1 1 auto;
			margin-right: 0.75rem;
		}

		button {
			@extend %load-more;
			background-color: $black-bg-color;
			border-color: $black-bg-color;
			color: $button-primary-color;
			font-weight: $font-weight-semi-bold;
			height: 3.25rem;
			min-width: 3.75rem;
			padding: 0;
			text-align: center;
			text-transform: uppercase;

			&:hover {
				background-color: $button-primary-color;
			}
		}
	}

	&-compact {
		background-color: $body-background;
		border: 0.125rem solid $body-font-color;
		border-radius: 0.063rem;
		display: flex;
		flex-direction: row;
		max-width: 40rem;
		outline: 0.063px solid $body-font-color;
		width: 100%;

		&-inactive {
			border-color: $color-dimgrey;
			outline: 0.063px solid transparent;
		}

		input:not(.combobox-input),
		.combobox-search {
			border: 0;
			flex: 1 2 auto;
			padding: 0.5rem 0.75rem;

			@include breakpoint-up(lg) {
				padding: 1.2rem;
			}
		}

		button {
			align-items: center;
			color: $button-primary-color;
			display: flex;

			@include breakpoint-up(lg) {
				padding: 0 0.75rem;
			}
		}

		svg {
			height: 1rem;
			width: 1rem;

			@include breakpoint-up(lg) {
				height: 1.4rem;
				width: 1.4rem;
			}
		}
	}
}

.search-bar-container {
	display: flex;
	justify-content: center;
}

.search-controls {
	display: flex;
	flex-wrap: wrap;

	.search-title {
		flex: 0 0 100%;
		font-weight: $font-weight-medium;
		order: 1;

		@include breakpoint-up(lg) {
			flex: 1;
			font-size: 1.125rem;
			order: 2;
			text-align: left;
		}
	}

	.recipe-filters {
		flex: 1;
		order: 2;

		@include breakpoint-up(lg) {
			flex: 0 0 100%;
			order: 1;
		}
	}
}

.search-type-menu {
	flex: 1;
	order: 3;
	position: relative;
	text-align: right;

	@include breakpoint-up(lg) {
		align-items: flex-end;
		display: flex;
		flex: 1;
		justify-content: flex-end;
		order: 3;
	}

	&-toggle {
		content: '';
		display: inline-block;
		margin: 0 0 0 0.875rem;
		transition: $default-transition;

		&.down {
			@include css-arrow(down, 0.0938rem solid $body-font-color, 0.5rem, false);
		}

		&.up {
			@include css-arrow(up, 0.0938rem solid $body-font-color, 0.5rem, false);
		}
	}

	> button {
		font-size: 0.75rem;
		padding: 0.5rem 0;

		@include breakpoint-up(lg) {
			font-size: 0.875rem;
			padding: 1.25rem 0 0.5rem;
		}

		strong {
			letter-spacing: 0.0475rem;
		}
	}

	> div {
		background-color: $body-background;
		border-radius: 0.0625rem;
		box-shadow: 0 0.1875rem 0.625rem rgb(0 0 0 / 0.2);
		padding: 0.375rem 0;
		position: absolute;
		right: 0;
		top: 3rem;
		width: 9.375rem;
		z-index: 1;

		@include breakpoint-up(lg) {
			top: 4.625rem;
		}

		button {
			color: $matte-black;
			display: block;
			font-size: 0.75rem;
			font-weight: $font-weight-medium;
			padding: 0.75rem 1.406rem;
			text-align: left;
			width: 100%;

			@include breakpoint-up(lg) {
				/* stylelint-disable-next-line max-nesting-depth */
				&:hover {
					background-color: $color-amour;
				}
			}
		}

		.search-type-active {
			background-color: $color-amour;
		}
	}
}
