.ad-wrapper {
	align-items: center;
	display: flex;
	flex-direction: column;
	margin: 2rem auto;
	overflow: hidden;
	width: 100%;

	@include breakpoint-up(lg) {
		min-height: 7.125rem;
	}

	/* Leaderboard ads should have different margin-bottom, depending on the breakpoint */
	&.leaderboard-ad {
		margin-bottom: 1.25rem;

		@include breakpoint-up(md) {
			margin-bottom: 1.875rem;
		}

		@include breakpoint-up(lg) {
			margin-bottom: 6rem;
		}
	}

	&.sponsor-ad {
		display: flex;
		justify-content: center;
	}

	&.hide-mobile {
		@include breakpoint-between(xs, md) {
			display: none;
		}
	}

	&.hide-tablet {
		@include breakpoint-between(md, lg) {
			display: none;
		}
	}

	&.hide-desktop {
		@include breakpoint-up(lg) {
			display: none;
		}
	}
}

.ad-label {
	color: $ad-label-font-color;
	display: block;
	font-family: $title-font;
	font-size: 0.563rem;
	font-weight: $font-weight-medium;
	letter-spacing: 0.0875rem;
	line-height: 1.11;
	margin-bottom: 0.875rem;
	text-align: center;
}

/* stylelint-disable-next-line selector-class-pattern */
.adChoices_overlayContainer {
	line-height: 1.5;
}
