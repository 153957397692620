$sign-up-btn-width-mobile: 4.875rem;

.newsletter-stand-alone {
	display: flex;
	flex-direction: column;
	padding: 1.25rem var(--body-margin) 4.75rem;

	@include breakpoint-up(lg) {
		flex-direction: row;
		margin-top: -$mobile-navbar-height;
		padding: 2.5rem var(--body-margin) 4.1875rem;
	}

	.email-input {
		border-bottom: 0.0625rem solid $black-bg-color;

		input {
			appearance: none;
			background-color: transparent;
			border: 0;
			border-radius: 0;
			font-size: 1rem;
			font-weight: 400;
			padding-bottom: 0.625rem;
			padding-left: 0;
			width: calc(100% - $sign-up-btn-width-mobile - 2%);

			@include breakpoint-up(lg) {
				font-size: 1.375rem;
				letter-spacing: 0.037rem;
			}

			&:focus {
				box-shadow: none;
				outline-style: none;
			}
		}

		::placeholder {
			color: $color-grey-65;
			opacity: 1;
		}
	}

	.email-submit {
		position: absolute;
		right: 0;
		top: -0.7rem;

		@include breakpoint-up(lg) {
			top: -1.2rem;
		}

		input {
			appearance: none;
			background-color: $primary-color;
			border: 0;
			border-radius: 0;
			color: $button-primary-color;
			font-size: 0.625rem;
			font-weight: 600;
			height: 2.438rem;
			letter-spacing: 0.05rem;
			line-height: 1.4;
			text-align: center;
			text-transform: uppercase;
			width: $sign-up-btn-width-mobile;

			@include breakpoint-up(lg) {
				font-size: 0.75rem;
				font-weight: 600;
				height: 3.375rem;
				letter-spacing: .05rem;
				line-height: 1.4166;
				text-align: center;
				width: 7.4319rem;
			}
		}
	}

	.newsletter-block {
		width: 100%;

		h1 {
			font-size: 1.25rem;
			font-weight: 500;
			letter-spacing: 0.031rem;
			line-height: 1.4;
			margin: 0;
			padding: 0;

			@include breakpoint-up(lg) {
				font-size: 2.4rem;
				line-height: 1.0416;
			}

			@include breakpoint-up(xl) {
				font-size: 3.125rem;
				line-height: 1.12;
			}
		}

		p {
			font-family: $title-font;
			font-size: .9375rem;
			font-weight: 400;
			letter-spacing: .0313rem;
			line-height: 1.6666;

			@include breakpoint-up(lg) {
				font-size: 1.25rem;
				line-height: 1.6;
				max-width: 25.5625rem;
			}
		}

		&__wrapper {
			display: block;

			@include breakpoint-up(lg) {
				align-items: center;
				display: grid;
				gap: 0 8.125rem;
				grid-template-columns: 1.5fr 2fr;
				grid-template-rows: 1fr;
			}
		}

		&__image {
			margin-bottom: 2.5rem;
			@include breakpoint-up(lg) {
				margin-bottom: 0;
				order: 2;
			}

			.corus-image-wrapper {
				max-width: 100% !important;
			}

			img {
				width: 100%;
			}
		}

		&__image-desktop {
			display: none;
			@include breakpoint-up(lg) {
				display: block;
			}
		}

		&__image-mobile {
			@include breakpoint-up(lg) {
				display: none;
			}
		}

		&__title {
			margin-bottom: .625rem;
			width: 100%;

			@include breakpoint-up(lg) {
				margin-bottom: 2rem;
			}
		}

		&__description {
			margin-bottom: 3rem;

			@include breakpoint-up(lg) {
				margin-bottom: 6rem;
			}
		}

		&__terms {
			margin-top: 2.2rem;
			text-align: left;

			@include breakpoint-up(lg) {
				margin-top: 1.5rem;
			}

			p,
			a {
				font-family: $title-font;
				font-size: 0.75rem;
				font-weight: 500;
				letter-spacing: 0.019rem;
				line-height: 1.4173;
			}

			a {
				text-decoration: underline;
			}
		}

		&__form {
			width: 100%;

			input,
			p,
			a {
				font-family: $title-font;
			}

			.email-submit {
				display: inline-block;
			}
		}

		&__input-fields-wrapper {
			position: relative;
		}

		&__success {
			padding: 2rem 3rem;

			@include breakpoint-up(lg) {
				padding: 12.5rem 3rem;
			}

			p {
				font-family: $title-font;
				font-size: 1.5625rem;
				font-weight: 500;
				letter-spacing: .0313rem;
				line-height: 1.6;
				text-align: center;

				/* stylelint-disable-next-line max-nesting-depth */
				@include breakpoint-up(lg) {
					font-size: 2.5rem;
					line-height: 1.225;
				}
			}
		}

		&__error {
			align-items: center;
			display: flex;
			margin-top: 1rem;

			p {
				font-size: 0.75rem;
				font-weight: 400;
				letter-spacing: 0.019rem;
				line-height: 1.4173;
				margin: 0;
			}
		}
	}

	.error-icon {
		background-color: $primary-color;
		border-radius: 50%;
		display: inline-block;
		height: 1rem;
		margin-right: 1.25rem;
		position: relative;
		width: 1rem;

		@include breakpoint-up(lg) {
			margin-right: 0.625rem;
		}

		span {
			color: $button-primary-color;
			font-size: 0.75rem;
			font-weight: 900;
			left: 50%;
			line-height: 1.4173;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}
}
