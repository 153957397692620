.outbrain-widget {
	background: $color-amour;
	padding-bottom: 3.125rem;
	padding-top: 0.5rem;

	// Style adjustments to match "More to Explore" header on Slice.
	.ob-widget-header {
		border-top: 1px solid #000;
		margin: 0.5rem 0 0.75rem;
		padding: 1.25rem 0;
	}
}

// Override the `margin: 0px 128px 20px 128px;` Outbrain is applying to the Chefs & Hosts Profile widget.
// which causes the widget to not fit the content well.
.page-Profile,
.profile-outbrain-wrapper {
	.outbrain-widget {
		.ob-widget.ob-feed-layout {
			margin-bottom: 1.25rem;
			margin-left: var(--body-margin);
			margin-right: var(--body-margin);
			margin-top: 0;
		}
	}
}

.related-content + .content-well {
	.outbrain-widget {
		background: $body-background;
	}
}

.article-outbrain {
	.outbrain-widget {
		padding-top: 3rem;

		@include breakpoint-up(lg) {
			padding-bottom: 5.625rem;
		}
	}
}

.homepage-tag-sections-container {
	.outbrain-widget {
		@include breakpoint-up(lg) {
			padding-bottom: 5.625rem;
		}
	}
}

.recipe-outbrain {
	.outbrain-widget {
		padding-bottom: 5.625rem;
		padding-top: 3rem;
	}
}
