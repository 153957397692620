.predictive-search-bar {
	&-menu,
	&-compact {
		input {
			appearance: none;
			border: 0;
			font-family: $accent-font;
			font-size: 1rem;
			font-weight: $font-weight-medium;
			letter-spacing: 0.063rem;
			width: 100%;

			&:focus {
				box-shadow: none;
				outline-style: none;
			}
		}
	}


	&-menu {

		display: flex;
		width: 100%;

		@include breakpoint-up(xl) {
			position: absolute;
			top: -4.5rem;
			width: calc(100% - 4.5rem);
		}

		/*
			In the ComboboxSearch component, the `input` element is wrapped in a `label` element,
			so the margin-right does not affect the sibling `button` element as in the SearchBar component.
		*/
		input:not(.combobox-input),
		.combobox-search {
			border: 0;
			border-bottom: 0.125rem solid $black-bg-color;
			flex: 1 1 auto;
			margin-right: 0.75rem;
		}

		.combobox-input {
			border: 0 !important;
			height: 100%;
			padding-left: 0.625rem;
			width: 100%;
		}

		button {
			@extend %load-more;
			background-color: $black-bg-color;
			border-color: $black-bg-color;
			color: $button-primary-color;
			font-weight: $font-weight-semi-bold;
			height: 3.25rem;
			min-width: 3.75rem;
			padding: 0;
			text-align: center;
			text-transform: uppercase;

			&:hover {
				background-color: $button-primary-color;
			}
		}
	}

	&-compact {
		background-color: $body-background;
		border: 0.125rem solid $body-font-color;
		border-radius: 0.063rem;
		display: flex;
		flex-direction: row;
		max-width: 40rem;
		outline: 0.063px solid $body-font-color;
		width: 100%;

		&-inactive {
			border-color: $color-dimgrey;
			outline: 0.063px solid transparent;
		}

		input:not(.combobox-input),
		.combobox-search {
			border: 0;
			flex: 1 2 auto;
			padding: 0.5rem 0.75rem;

			@include breakpoint-up(lg) {
				padding: 1rem;
			}
		}

		button {
			align-items: center;
			color: $button-primary-color;
			display: flex;

			@include breakpoint-up(lg) {
				padding: 0 0.75rem;
			}
		}

		svg {
			height: 1rem;
			width: 1rem;

			@include breakpoint-up(lg) {
				height: 1.4rem;
				width: 1.4rem;
			}
		}
	}
}

.search-bar-container {
	display: flex;
	justify-content: center;
	position: relative;

	.predictive-search-bar-compact {
		position: relative;
	}

	.combobox-options {
		left: 0;
		margin-top: 1.5rem;
	}
}

.search-controls {
	display: flex;
	flex-wrap: wrap;

	.search-title {
		flex: 0 0 100%;
		font-weight: $font-weight-medium;
		order: 1;

		@include breakpoint-up(lg) {
			flex: 1;
			font-size: 1.125rem;
			order: 2;
			text-align: left;
		}
	}

	.recipe-filters {
		flex: 1;
		order: 2;

		@include breakpoint-up(lg) {
			flex: 0 0 100%;
			order: 1;
		}
	}
}

.search-type-menu {
	flex: 1;
	order: 3;
	position: relative;
	text-align: right;

	@include breakpoint-up(lg) {
		align-items: flex-end;
		display: flex;
		flex: 1;
		justify-content: flex-end;
		order: 3;
	}

	&-toggle {
		content: '';
		display: inline-block;
		margin: 0 0 0 0.875rem;
		transition: $default-transition;

		&.down {
			@include css-arrow(down, 0.0938rem solid $body-font-color, 0.5rem, false);
		}

		&.up {
			@include css-arrow(up, 0.0938rem solid $body-font-color, 0.5rem, false);
		}
	}

	> button {
		font-size: 0.75rem;
		padding: 1.25rem 0 0.5rem;

		@include breakpoint-up(lg) {
			font-size: 0.875rem;
		}

		strong {
			letter-spacing: 0.0475rem;
		}
	}

	> div {
		background-color: $body-background;
		border-radius: 0.0625rem;
		box-shadow: 0 0.1875rem 0.625rem rgb(0 0 0 / 0.2);
		padding: 0.375rem 0;
		position: absolute;
		right: 0;
		top: 3rem;
		width: 9.375rem;
		z-index: 1;

		@include breakpoint-up(lg) {
			top: 4.625rem;
		}

		button {
			color: $matte-black;
			display: block;
			font-size: 0.75rem;
			font-weight: $font-weight-medium;
			padding: 0.75rem 1.406rem;
			text-align: left;
			width: 100%;

			@include breakpoint-up(lg) {
				/* stylelint-disable-next-line max-nesting-depth */
				&:hover {
					background-color: $color-amour;
				}
			}
		}

		.search-type-active {
			background-color: $color-amour;
		}
	}
}

.combobox-search {
	width: 100%;
}

/*
Styles for the combobox options listbox.
*/
.combobox-options {
	background-color: $body-background;
	border: 1px solid $body-font-color;
	font-family: $accent-font;
	font-weight: $font-weight-medium;
	list-style: none;
	margin-top: 1.25rem;
	max-height: 12.5rem;
	overflow-y: scroll;
	padding: 0;
	position: absolute;
	text-transform: none;
	width: 100%;
	z-index: 9999;

	@include breakpoint-up(md) {
		max-height: unset;
	}

	div {
		cursor: pointer;
		padding: 1rem;

		@include breakpoint-up(lg) {
			padding: 0.5rem 1rem;
		}
	}

	//li[aria-selected="true"],
	/* stylelint-disable-next-line selector-no-qualifying-type */
	div[data-headlessui-state*='active'],
	/* stylelint-disable-next-line selector-no-qualifying-type */
	div[data-headlessui-state*='active'] > span {
		background-color: $color-grey-95;
		color: $primary-color;
		font-size: 1.25rem;
		font-weight: $font-weight-bold;
	}
}
