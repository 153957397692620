.rating {
	--star-fill-colour: #{$primary-color};
	--star-stroke-colour: #{$primary-color};
	align-items: baseline;
	display: flex;
	line-height: 0;

	&.rate .star {
		--star-fill-colour: #{$primary-color};
		--star-stroke-colour: #{$primary-color};
		cursor: pointer;
	}

	.star {
		height: 0.75rem;
		width: 0.75rem;
	}

	svg {
		height: 100%;
		pointer-events: none;

		path {
			stroke: var(--star-stroke-colour);
		}

		path:nth-child(1) {
			stroke: none;
		}
	}

	.rating-empty {
		/* stylelint-disable-next-line selector-max-compound-selectors */
		svg path {
			fill: none;
		}
	}
	
	.rating-full {
		/* stylelint-disable-next-line selector-max-compound-selectors */
		svg path {
			fill: var(--star-fill-colour);
		}
	}
}

.rate:hover {
	.star {
		path {
			fill: var(--star-fill-colour);
		}
	}

	.star:hover ~ .star {
		/* stylelint-disable-next-line selector-max-compound-selectors */
		path {
			fill: none;
		}
	}
}
