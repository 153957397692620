.promo {
	background-color: $body-background;
	display: flex;
	flex-direction: column;
	margin: 3.75rem 0;
	padding: 2.125rem var(--body-margin);

	@include breakpoint-up(sm) {
		padding: 4rem var(--body-margin) 2.125rem;
	}

	@include breakpoint-up(lg) {
		flex-direction: row;
		padding: 4rem var(--body-margin);
	}

	@include breakpoint-up(xl) {
		margin: 4.375rem 0;
	}

	&-image-wrapper {
		flex: 0 1 auto;
		margin: 1rem 0;

		@include breakpoint-up(lg) {
			flex: 0 0 37.5rem;
		}

	}

	&-details {
		display: flex;
		flex: 1 1 auto;
		flex-direction: column;
		margin: 1rem 5%;
		text-align: center;

		@include breakpoint-up(lg) {
			margin: 2rem 0 0 2rem;
			text-align: left;
		}

		&-title {
			font-size: 1.625rem;
			font-weight: $font-weight-semi-bold;
			letter-spacing: 0.0775rem;
			line-height: 1.3846;
			margin: 1rem 5%;

			@include breakpoint-up(lg) {
				font-size: 1.75rem;
				letter-spacing: 0.1375rem;
				line-height: 1.2857;
				margin: 0 0 0.75rem;
			}

			@include breakpoint-up(xl) {
				font-size: 2rem;
				line-height: 1.4375;
				margin: 0 0 1rem;
			}
		}

		&-content {
			p {
				font-size: 0.875rem;
				letter-spacing: 0.0475rem;
				line-height: 1.7142;

				/* stylelint-disable-next-line max-nesting-depth */
				@include breakpoint-up(sm) {
					font-size: 1rem;
					line-height: 1.75;
				}

				/* stylelint-disable-next-line max-nesting-depth */
				@include breakpoint-up(lg) {
					font-size: 1.125rem;
					letter-spacing: 0.0625rem;
				}

				/* stylelint-disable-next-line max-nesting-depth */
				a {
					text-decoration: underline;
					text-decoration-color: $primary-color;
					text-underline-offset: 0.0625rem;
					transition: $default-transition;

					/* stylelint-disable-next-line max-nesting-depth */
					&:hover {
						color: $primary-color;
					}
				}
			}
		}


		&-promo-button-container {
			margin: 2rem 0;

			@include breakpoint-up(sm) {
				margin: 2.1875rem 0;
			}

			@include breakpoint-up(lg) {
				margin: 1.5rem 0;
			}

			.promo-button {
				@extend %load-more;
			}
		}

		&-disclaimer {
			font-size: 0.563rem;
			font-style: italic;
			letter-spacing: 0.0344rem;
			line-height: 1.1101;
			margin-top: 1.25rem;

			@include breakpoint-up(sm) {
				margin-top: 2.5rem;
			}

			@include breakpoint-up(lg) {
				font-size: 0.625rem;
				letter-spacing: 0.0431rem;
				line-height: 1.3;
				margin-top: 1rem;
			}

			@include breakpoint-up(xl) {
				margin-top: 2rem;
			}
		}
	}
}
