.recipe-filters-desktop {
	display: none;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 2rem;
	margin-top: 5.625rem;
	padding: 0 6%;

	@include breakpoint-up(lg) {
		display: flex;
	}

	@include breakpoint-up(xl) {
		padding: 0 6.75rem;
	}
}

.selected-filters-section {
	border: 0;
	padding: 0;

	@include breakpoint-up(lg) {
		border-bottom: 0.0625rem solid $color-grey-85;
		padding: 0 6%;
	}

	@include breakpoint-up(xl) {
		padding: 0 6.75rem;
	}
}

.selected-filters {
	align-items: flex-start;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0;

	@include breakpoint-up(lg) {
		margin: 0.5rem 0;
	}
}

.selected-filters-clear {
	display: none;
	font-family: $title-font;
	font-size: 0.75rem;
	font-weight: $font-weight-medium;
	letter-spacing: 0.0362rem;
	line-height: 1.3333;
	padding: 1rem 0 2.5rem;
	text-align: left;
	text-decoration: underline;

	@include breakpoint-up(lg) {
		display: block;
	}
}

.selected-filter {
	align-items: center;
	background-color: $primary-color;
	border-radius: 0.0625rem;
	color: $button-primary-color;
	display: flex;
	flex-direction: row;
	margin: 0.5rem 0.5rem 0.5rem 0;
	padding: 0 0.625rem;

	@include breakpoint-up(lg) {
		margin-right: 1rem;
	}

	&-name {
		font-family: $title-font;
		font-size: 0.6875rem;
		font-weight: $font-weight-medium;
		letter-spacing: 0.0475rem;
		line-height: 2.909;
	}

	&-unselect {
		cursor: pointer;
		margin-left: 1rem;
		position: relative;
		transition: height $default-transition;
		width: 0.625rem;

		&:hover {
			&::before,
			&::after {
				/* stylelint-disable-next-line max-nesting-depth */
				@include breakpoint-up(lg) {
					scale: 110%;
					top: -0.2rem;
				}
			}
		}
	}

	&-unselect::before,
	&-unselect::after {
		background-color: $body-background;
		content: ' ';
		height: 0.625rem;
		left: 0.25rem;
		position: absolute;
		top: -0.2rem;
		width: 0.125rem;

		@include breakpoint-up(lg) {
			top: -0.2rem;
		}
	}

	&-unselect::before {
		transform: rotate(45deg);
	}

	&-unselect::after {
		transform: rotate(-45deg);
	}
}
