.is-page-title {
	margin-bottom: 0;
	margin-left: auto;
	margin-right: auto;
	margin-top: 2.5rem;
	max-width: 13.438rem;
	text-align: center;
	text-transform: uppercase;

	@include breakpoint-up(sm) {
		margin-bottom: 6.5rem;
		margin-top: 1.5rem;
		max-width: 100%;
	}
}

.is-container {
	display: flex;
	flex-direction: row;
	margin-bottom: 2.5rem;
	margin-left: var(--body-margin);
	margin-right: var(--body-margin);
	margin-top: 1rem;

	.ad-wrapper.hide-desktop {
		margin-bottom: 1.969rem;
		margin-top: 1rem;
		padding-bottom: 1.969rem;
		position: relative;

		&::after {
			background-color: $color-grey-85;
			content: '';
			height: 1.5px;
			position: relative;
			top: 1.969rem;
			width: 66%;
		}
	}

	.is-description {
		margin-bottom: 0;

		@include breakpoint-up(sm) {
			margin-bottom: 6.75rem;
		}
	}

	.is-category {
		border: 0;
		padding-bottom: 60px;
	}

	.is-content {
		display: flex;
		flex-direction: column;
		max-width: 100%;
		min-width: 100%;
		overflow-x: hidden;
		overflow-y: display;
		width: 100%;

		@include breakpoint-up(lg) {
			min-width: initial;
			width: auto;
		}
	}

	a {
		border-bottom: 1px solid $primary-color;
		text-decoration: none;
	}

	.is-group-name {
		margin-top: 0;
		text-align: center;
	}

	.ig-sub-tables {
		display: flex;
		flex-direction: row;
	}

	.is-group-table {
		border-left: 1px solid $ig-table-grey;
		border-spacing: 0;
		border-top: 1px solid $ig-table-grey;
		max-width: 100%;
		padding: 0;
		width: 100%;

		th {
			background-color: $body-background;
			border-right: 1px solid $ig-table-grey;
			color: $ig-table-charcoal;
			font-family: 'Jost', sans-serif;
			font-size: 0.75rem;
			font-weight: 500;
			letter-spacing: 0.96px;
			line-height: 1.6666;
			margin: 0;
			padding: 0.5rem 1rem;
			vertical-align: top;
			word-wrap: break-word;

			@include breakpoint-up(lg) {
				font-size: 0.88rem;
			}
		}

		td {
			background-color: $ig-table-grey;
			border-bottom: 1px solid #fff;
			border-right: 1px solid #fff;
			color: $black-bg-color;
			font-size: 0.75rem;
			font-weight: 500;
			letter-spacing: 0.96px;
			line-height: 1.6666;
			margin: 0;
			padding: 0.5rem 1rem;
			vertical-align: top;
			word-wrap: break-word;

			@include breakpoint-up(lg) {
				padding: 0.625rem;
			}

			/* stylelint-disable-next-line selector-max-compound-selectors */
			.is-subst-substitute {
				border-right: 1px solid $ig-table-grey;
			}
		}

		tr:last-child {
			/* stylelint-disable-next-line selector-max-compound-selectors */
			.is-subst-substitute {
				border-bottom: 1px solid $ig-table-grey;
			}
		}

		.is-subst-ingredient,
		.is-subst-amount,
		.is-subst-substitute {
			max-width: 100%;
			word-wrap: break-word;

			@include breakpoint-up(lg) {
				font-size: 0.88rem;
			}
		}


		th:nth-child(2),
		td:nth-child(2) {
			text-align: center;
		}

		th:last-child,
		td:last-child {
			width: 50%;
		}
	}
}

.sticky-ad-container {
	.ad-wrapper {
		position: sticky;
		top: 0.75rem;
	}
}

.is-right-rail-ad {
	margin-left: 2.5rem;
	max-width: 300px;
	min-width: 300px;
	width: 300px;

	&-1 {
		margin: -1.5rem 0 0;
		position: sticky;
		top: 0.75rem;
	}

	&-2 {
		@include breakpoint-between(xs, lg) {
			margin: 1.375rem 0 0;
			padding: 2rem 0;
		}
	}
}
