/**
 * Visibility utilities.
 */

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
	border: 0 !important;
	clip: rect(0, 0, 0, 0) !important;
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	white-space: nowrap !important;
	width: 1px !important;
}

.d-block {
	display: block;
}

.d-none {
	display: none;
}

.d-block-lg {
	@include breakpoint-up(lg) {
		display: block;
	}
}

.d-none-lg {
	@include breakpoint-up(lg) {
		display: none !important;
	}
}
