.newsletter-link {
	display: flex;
	justify-content: center;
	margin: 0.9375rem 0 0;
	padding: 0;

	a {
		border: 0.0625rem solid $button-primary-color;
		color: $button-primary-color;
		cursor: pointer;
		display: flex;
		font-family: $title-font;
		font-size: 0.625rem;
		font-style: normal;
		font-weight: 500;
		justify-content: center;
		letter-spacing: 0.045rem;
		line-height: 1.7;
		min-width: 10rem;
		padding: 0.375rem 0;
		text-align: center;

		&:hover {
			border: 0.0625rem solid $secondary-color;
			color: $secondary-color;
			transition: $default-transition;
		}
	}

	@include breakpoint-up(xl) {
		a {
			margin: 0.6875rem 0;
			min-width: 0;
			padding: 0.3125rem;
		}
	}
}
