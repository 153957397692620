.short-videos-drawer {
	background-color: $black-bg-color;
	margin: 4.375rem 0;
	padding: 2.5rem 0;

	@include breakpoint-up(lg) {
		padding: 5rem 0;
	}

	&-lightbox-open {
		overflow: hidden;

		.header {
			z-index: 0;
		}

		/* stylelint-disable-next-line selector-class-pattern */
		.adChoices_overlayContainer,
		/* stylelint-disable-next-line selector-max-id */
		#_evh-link {
			z-index: -1;
		}
	}

	&__wrapper {
		margin: 0 var(--body-margin);
		position: relative;

		@include breakpoint-up(xl) {
			margin: 0 auto;
			max-width: $max-content-well-width;
		}
	}

	&__heading {
		color: $button-primary-color;
		font-family: $accent-font;
		font-size: 1.375rem;
		font-weight: $font-weight-medium;
		letter-spacing: 0.0775rem;
		line-height: 1;
		margin: 0;
		padding-bottom: 1rem 0;
		text-transform: uppercase;

		@include breakpoint-up(sm) {
			font-size: 1.75rem;
			letter-spacing: 0.1206rem;
		}

		@include breakpoint-up(md) {
			padding-bottom: 0;
		}
	}

	&__lightbox {
		background: rgba(0, 0, 0, 0.9);
		height: 100%;
		left: 0;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 99999999;
	}

	&__lightbox-close {
		color: $button-primary-color;
		font-size: 1.875rem;
		height: 3rem;
		position: absolute;
		right: 1.25rem;
		top: 1.25rem;
		width: 3rem;
	}

	&__lightbox-wrapper {
		align-items: center;
		display: flex;
		height: 100%;
		justify-content: center;
	}

	&__lightbox-main {
		margin: 0 auto;
		max-height: 100vh;
		max-width: 100%;
		min-width: 20.25rem;
		overflow: auto;
		position: relative;
		text-align: center;

		.slick-slider {
			min-height: 18.75rem;

			@include breakpoint-up(xl) {
				min-height: 50rem;
				width: 50rem;
			}
		}

		.slick-arrow {
			@include breakpoint-up(sm) {
				background-color: $primary-color;

				/* stylelint-disable-next-line max-nesting-depth */
				&:hover {
					background-color: $banner-bg-color;
				}
			}
		}
	}

	&__lightbox-item {
		margin: 0 auto;
		width: 100%;

		@include breakpoint-up(sm) {
			width: 26.5625rem !important;
		}
	}

	&__items {
		margin-top: 1.875rem;
		overflow: hidden;

		@include breakpoint-up(lg) {
			margin-top: 2.1875rem;
		}

		.slick-slide > div {
			margin-right: 0.625rem;

			@include breakpoint-up(md) {
				margin-right: 1rem;
			}
		}

		[aria-hidden = true] {
			opacity: 0.5;
			pointer-events: none;
		}

		.slick-list {
			overflow: visible;
		}

		.slick-arrow {
			background-color: $primary-color;

			&:hover {
				background-color: $banner-bg-color;
			}
		}
	}

	&__image-wrapper {
		position: relative;
		z-index: -1;

		img {
			width: 100%;
		}
	}

	&__overlay {
		background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, .5) 77%, rgba(0, 0, 0, .5) 100%) 100% 100% / 100% 15rem no-repeat transparent;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		transition: opacity 250ms cubic-bezier(0, .25, .25, 1), background-size 250ms cubic-bezier(0, .25, .25, 1);
		width: 100%;
	}

	&__item {
		display: block;
		line-height: 0;
		margin-bottom: 0.125rem;
		margin-top: 0.125rem;
		padding: 0;
		position: relative;
		text-align: left;
		transition: box-shadow 0.3s ease;
		width: 100%;

		&:hover,
		&:focus {
			box-shadow: inset 0 0 0 0.125rem $secondary-color;
			cursor: pointer;
		}
	}

	&__details-wrapper {
		bottom: 0;
		line-height: normal;
		padding: 0.9375rem;
		position: absolute;
		width: 100%;
	}

	&__video-title {
		color: $button-primary-color;
		font-family: $accent-font;
		font-size: 0.875rem;
		font-weight: $font-weight-medium;
		letter-spacing: 0.0156rem;
		line-height: 1.5;
	}

	&__video-control {
		align-items: center;
		display: flex;

	}

	&__video-play-icon {
		align-items: center;
		background-color: $primary-color;
		border-radius: 50%;
		display: flex;
		height: 2rem;
		justify-content: center;
		margin-right: 0.625rem;
		width: 2rem;

		span {
			border-bottom: 0.4375rem solid transparent;
			border-left: 0.75rem solid $button-primary-color;
			border-top: 0.4375rem solid transparent;
			height: 0;
			margin-left: 0.1875rem;
			width: 0;
		}
	}

	&__video-duration {
		color: $button-primary-color;
		font-family: $accent-font;
		font-size: 0.75rem;
		font-weight: $font-weight-medium;
		letter-spacing: 0.0156rem;
		line-height: 1.25;
	}

	.corus-image-wrapper {
		background-color: $color-grey-12;
		max-width: 100% !important;
	}

	.slick-arrow.slick-disabled {
		display: none;
	}

	.slick-arrow {
		border-radius: 0;
		display: inline-block;
		height: 3.125rem;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		transition: all $default-transition;
		width: 3.125rem;
		z-index: 1;
		@include hide-text;
		@include focus-outline($body-background);
	}

	.slick-next {
		right: 0;

		&::after {
			@include css-arrow(right, 0.1rem solid $body-background, 1rem, true);
			content: '';
			transition: $default-transition;
		}
	}

	.slick-prev {
		left: 0;

		&::after {
			@include css-arrow(left, 0.1rem solid $body-background, 1rem, true);
			content: '';
			transition: $default-transition;
		}
	}

	.VideoPlayer--vertical {
		max-width: 100%;

		@include breakpoint-between(md, xl) {
			max-width: 23.75rem;
		}

		@include breakpoint-up(xl) {
			max-width: 28.25rem;
		}
	}

	.article-video {
		position: relative;
	}
}
