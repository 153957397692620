$max-content-width: 66.5625rem;
$body-about-us-margin: calc((100vw - #{$max-content-width})/2);

.about-us {
	display: flex;
	flex-direction: column;
	padding: 0;

	@include breakpoint-up(xl) {
		margin: -4.5rem 0 0;
	}

	&__header {
		align-items: center;
		background-color: $banner-bg-color;
		display: flex;
		height: 12.5rem;
		justify-content: center;

		@include breakpoint-up(xl) {
			height: 25.3125rem;
		}
	}

	&__image {
		height: auto;
		width: 14.375rem;

		@include breakpoint-up(xl) {
			padding: 0;
			width: 28.4375rem;
		}
	}

	&__main__information_team {
		background-image: linear-gradient($body-background 0%, $color-amour 100%);
		padding: 2.5rem var(--body-margin) 0;

		@include breakpoint-up(xl) {
			padding: 3.75rem $body-about-us-margin 0;
		}

		.information {
			margin: 0 0 3.3125rem;

			@include breakpoint-up(xl) {
				margin: 0 0 3.875rem;
			}
		}
		

		p {
			color: $body-font-color;
			font-family: $body-font-family;
			font-size: 1.125rem;
			letter-spacing: 0.0187rem;
			line-height: 1.8888;
			margin: 0;

			@include breakpoint-up(xl) {
				font-size: 1.375rem;
				letter-spacing: 0.0231rem;
				line-height: 1.8636;
			}
		}

		.team {
			padding: 0 0 3.75rem;

			@include breakpoint-up(xl) {
				padding: 0 0 5.125rem;
			}
		}

		h2 {
			color: $body-font-color;
			font-family: $accent-font;
			font-size: 1.25rem;
			font-weight: $font-weight-semi-bold;
			letter-spacing: 0.0206rem;
			line-height: 1.4;
			margin: 0 0 1.8125rem;

			@include breakpoint-up(xl) {
				margin: 0 0 1.375rem;
			}
		}

		.team-content {
			@include breakpoint-up(xl) {
				display: grid;
				grid-column-gap: 13.4375rem;
				grid-template-columns: repeat(2, 18.75rem);
			}
		}

	}

	&__main__team__member {
		margin: 0 0 2.4375rem;

		@include breakpoint-up(xl) {
			&:nth-child(7) {
				margin-bottom: 0;
			}
		}

		p {
			margin: 0;
		}

		.title {
			color: $color-stone;
			font-family: $accent-font;
			font-size: 0.6875rem;
			font-weight: $font-weight-medium;
			letter-spacing: 0.0437rem;
			line-height: 2.1818;
			margin-bottom: 0.5rem;

			@include breakpoint-up(xl) {
				font-size: 0.6875rem;
				letter-spacing: 0.0437rem;
				line-height: 2.1818;
			}
		}

		.fullname {
			color: $body-font-color;
			font-family: $body-font-family;
			font-size: 1.125rem;
			font-weight: $font-weight-medium;
			letter-spacing: 0.0019rem;
			line-height: 1.5;
		}

		.email {
			color: $body-font-color;
			font-family: $body-font-family;
			font-size: 1rem;
			font-weight: $font-weight-normal;
			letter-spacing: 0.0019rem;
			line-height: 1.6875;
		}

		.email-link {
			color: $body-font-color;
			text-decoration: none;

			&:hover {
				@include focus-outline($body-background);
				color: $body-font-color;
				-webkit-text-decoration-color: $primary-color;
				text-decoration-color: $primary-color;
				-webkit-text-decoration-line: underline;
				text-decoration-line: underline;
				-webkit-text-decoration-thickness: 0.0625rem;
				text-decoration-thickness: 0.0625rem;
			}
		}

		&:last-child {
			margin: 0;
		}
	}

	&__main__content {
		background-color: $black-bg-color;
		color: $body-background;
		padding: 3.125rem var(--body-margin);

		@include breakpoint-up(xl) {
			padding: 4.5rem $body-about-us-margin 5.125rem;
		}
	}

	&__main__content_item {
		margin: 0 0 3.1875rem;

		@include breakpoint-up(xl) {
			margin: 0 0 4.0625rem;
		}

		p {
			font-family: $body-font-family;
			font-size: 1rem;
			letter-spacing: 0.0019rem;
			line-height: 1.5;

			a {
				@include underline-text;
				color: $body-background;
			}
		}

		.title {
			font-family: $accent-font;
			font-size: 1.25rem;
			font-weight: $font-weight-semi-bold;
			letter-spacing: 0.0206rem;
			line-height: 1.5;
			margin: 0 0 0.9375rem;

			@include breakpoint-up(xl) {
				margin: 0 0 0.5rem;
			}
		}

		&:last-child {
			margin: 0;
		}
	}

	a {
		@include focus-outline($body-background);
		color: $body-font-color;
		-webkit-text-decoration-color: $primary-color;
		text-decoration-color: $primary-color;
		-webkit-text-decoration-line: underline;
		text-decoration-line: underline;
		-webkit-text-decoration-thickness: 0.0625rem;
		text-decoration-thickness: 0.0625rem;

		&:hover {
			color: $primary-color;
			cursor: pointer;
			-webkit-transition: 0.2s ease-out;
			transition: 0.2s ease-out;

		}
	}
}
