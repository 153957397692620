
.post-block-grid {
	&.with-ads {
		column-gap: var(--column-gap);
		row-gap: 0;

		.post-grid-ad {
			width: 100%;
			
			@include breakpoint-up(md) {
				grid-column: 1/3;
			}

			@include breakpoint-up(xl) {
				grid-column: 1/4;
			}
		}

		> * {
			margin: calc((var(--row-gap)) / 2);
		}

		.ad-wrapper {
			text-align: center;

			@include breakpoint-up(xs) {
				margin: 3rem 0;
			}

			@include breakpoint-up(md) {
				margin: 4.375rem 0;
			}
		}
	}

	/* stylelint-disable-next-line selector-max-id */
	#magnite {
		display: none;
	}

	/* stylelint-disable-next-line selector-max-id */
	#fsk_splitbox_6033_onscreen,
	/* stylelint-disable-next-line selector-max-id */
	#fsk_splitbox_6035_onscreen {
		display: none;
		margin: 0 auto;

		@include breakpoint-up(md) {
			grid-column: 1/3;
		}

		@include breakpoint-up(xl) {
			grid-column: 1/4;
		}

		/* stylelint-disable-next-line selector-max-id */
		> .sb-opened {
			margin: 1.25rem auto;

			@include breakpoint-up(md) {
				margin: 1.875rem auto;
			}
		}
	}
}


