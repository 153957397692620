.profile {
	// Chef & Host pages styling
	&.chef,
	&.host {
		background-image: linear-gradient($body-background 0%, $body-background 20%, $color-amour 100%);

		.profile-info {
			padding: 2.5rem 0;

			@include breakpoint-up(md) {
				padding: 3.75rem 0;
			}

			@include breakpoint-up(xl) {
				padding-bottom: 5.25rem;
			}
		}

		.profile-name {
			color: $body-font-color;
			font-family: $title-font;
			font-size: 1.625rem;
			font-weight: 500;
			letter-spacing: 0.0313rem;
			line-height: 1.3846;
			margin: 0;
			text-align: center;

			@include breakpoint-up(md) {
				font-size: 2.2rem;
				line-height: 1.1363;
			}

			@include breakpoint-up(lg) {
				font-size: 2.5rem;
				letter-spacing: 0.0481rem;
				line-height: 0.9;
			}
		}

		.profile-body {
			@include breakpoint-between(xs, lg) {
				background: $body-background;
			}

			@include breakpoint-up(lg) {
				flex: 1 0 33.75rem;
				margin-right: 4.6875rem;
				margin-top: 1.5rem;
			}
		}

		.profile-layout {
			@include breakpoint-up(lg) {
				display: flex;
				margin: 0 var(--body-margin);
			}
		}

		.profile-image {
			margin: 0;

			@include breakpoint-between(md, lg) {
				margin: 0 var(--body-margin);
			}
		}

		.profile-image-credit {
			color: $color-grey-35;
			display: none;
			font-family: $accent-font;
			font-size: 0.5625rem;
			font-weight: $font-weight-medium;
			letter-spacing: 0.0125rem;
			line-height: 1.4444;

			@include breakpoint-up(md) {
				display: block;
			}

			@include breakpoint-between(xs, lg) {
				margin: 0 var(--body-margin);
			}
		}

		.profile-desc {
			position: relative;

			@include breakpoint-between(xs, lg) {
				margin: 2.5rem var(--body-margin) 0;
			}

			@include breakpoint-up(lg) {
				margin: 2.5rem 0 3.125rem;
			}

			&.expanded-false::after {
				background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8) 90%);
				bottom: 0;
				content: '';
				height: 75%;
				left: 0;
				position: absolute;
				width: 100%;
				z-index: 1;
			}
		}

		.profile-show-more {
			font-size: 0.9rem;
			font-weight: $font-weight-medium;
			letter-spacing: 0.0437rem;
			margin: 0 var(--body-margin) 1rem;
			padding: 1rem 0;
			text-decoration: underline;

			span {
				margin-left: 0.5rem;
			}

			.profile-show-more-true {
				@include css-arrow(down, 0.15rem solid black, 0.6rem, false);
				transition: all $default-transition;
			}

			.profile-show-more-false {
				@include css-arrow(up, 0.15rem solid black, 0.6rem, false);
				transition: all $default-transition;
			}
		}
	}

	.profile-ad {
		background: transparent;

		&-1 {
			@include breakpoint-between(xs, lg) {
				margin: 1.375rem 0 0;
				padding: 2rem 0;
			}
		}
	}

	// Staff pages styling
	&.staff {
		background-image: linear-gradient($body-background 0%, $body-background 60%, $color-amour 100%);
		padding: 0 var(--body-margin) 0.1rem;

		@include breakpoint-up(md) {
			align-content: flex-start;
			display: grid;
			grid-column-gap: 1.5rem;
			grid-template-columns: 6.25rem 3fr;
			padding: 5.125rem var(--body-margin) 2rem;
		}

		.staff-profile-name {
			font-family: $title-font;
			font-size: 1.625rem;
			font-weight: $font-weight-semi-bold;
			letter-spacing: 0.0563rem;
			line-height: 0.7692;
			padding-bottom: 0.625rem;
			text-transform: uppercase;

			@include breakpoint-between(xs, md) {
				text-align: center;
			}
		}

		.staff-profile-pic {
			border-radius: 50%;
			margin: 2.625rem 0;
			object-fit: cover;
			text-align: center;

			@include breakpoint-up(md) {
				margin: .625rem 0;
			}
		}
	}

	.staff-profile-desc,
	.profile-desc {
		a {
			text-decoration: underline;
			text-decoration-color: $primary-color;
			text-underline-offset: 0.0625rem;
			transition: $default-transition;
	
			&:hover {
				color: $primary-color;
			}
		}
	}

	// Associated Recipes Styling for all author types
	&-recipes {
		background: $color-amour;
		padding: 2.8125rem 0 4.25rem;

		&-title {
			font-family: $title-font;
			font-size: 1.125rem;
			font-weight: $font-weight-medium;
			letter-spacing: 0.0775rem;
			line-height: 1.4444;
			margin: 0 var(--body-margin) 1.1875rem;
			text-align: left;
			text-transform: uppercase;

			@include breakpoint-up(lg) {
				font-size: 1.5rem;
				letter-spacing: 0.1031rem;
				line-height: 1.4583;
				margin: 1.25rem var(--body-margin) 2.5rem;
				text-align: left;
			}
		}
	}

	&-load-more {
		display: flex;
		justify-content: center;
		margin-top: 4.4375rem;

		&-btn {
			@extend %load-more;
		}
	}
}
