.recipe-filter {
	color: $recipe-filter-color;
	display: none;
	position: relative;

	@include breakpoint-up(lg) {
		display: block;
	}

	&.popup-open {
		.recipe-filter-header {
			color: $primary-color;
		}

		.recipe-filter-toggle {
			border-color: $primary-color;
		}
	}

	&-header {
		cursor: pointer;
		transition: $default-transition;

		&:hover {
			color: $primary-color;

			.recipe-filter-toggle {
				border-color: $primary-color;
			}
		}
	}

	&-title {
		font-family: $title-font;
		font-size: 0.875rem;
		font-weight: $font-weight-bold;
		letter-spacing: 0.0625rem;
		margin: 1.25rem 0;
		text-transform: uppercase;

		@include breakpoint-up(lg) {
			font-size: 0.875rem;
			font-weight: $font-weight-semi-bold;
			margin-right: 0.875rem;
			text-transform: none;
		}
	}

	&-toggle {
		content: '';
		display: inline-block;
		transition: $default-transition;

		&.down {
			@include css-arrow(down, 0.0938rem solid $body-font-color, 0.5rem, false);
		}

		&.up {
			@include css-arrow(up, 0.0938rem solid $body-font-color, 0.5rem, false);
		}
	}

	&-popup {
		background: $body-background;
		border-radius: 0.0625rem;
		box-shadow: 0 0.1875rem 0.625rem rgb(0 0 0 / 0.2);
		color: $recipe-filter-color;
		margin-top: 0.875rem;
		position: absolute;
		width: 21.75rem;
		z-index: 100;
	}

	&:nth-last-child(2) {
		.recipe-filter-popup {
			@include breakpoint-up(lg) {
				right: -17vw;
			}

			@include breakpoint-up(xl) {
				right: -17rem;
			}
		}
	}

	&:last-child {
		.recipe-filter-popup {
			@include breakpoint-up(lg) {
				right: -5vw;
			}

			@include breakpoint-up(xl) {
				right: -7rem;
			}
		}
	}

	&-list {
		display: grid;
		gap: 1.5rem 1rem;
		grid-template-columns: 1fr 1fr;
		margin: 0;
		padding: 0;

		@include breakpoint-up(lg) {
			gap: 1rem;
			margin: 2.5rem;
		}
	}

	&-item,
	&-item > label {
		display: flex;
		font-family: $title-font;
		font-size: 0.75rem;
		font-weight: $font-weight-medium;
		letter-spacing: 0.0519rem;
		line-height: 1.5;
		list-style: none;
	}

	&-item > label {
		cursor: pointer;
	}

	&-checkbox {
		appearance: none;
		border: 0.0625rem solid $recipe-filter-color;
		border-radius: 0.0625rem;
		display: inline;
		margin: 0;
		max-height: 1rem;
		max-width: 1rem;
		min-height: 1rem;
		min-width: 1rem;
		padding: 0;
		position: relative;
		transition: $default-transition;

		&:hover {
			border-color: $primary-color;
		}

		&.checked {
			background-color: $recipe-filter-color;

			&::after {
				border: solid $button-primary-color;
				border-width: 0 0.0938rem 0.0938rem 0;
				content: '';
				height: 0.3125rem;
				left: 0.3125rem;
				position: absolute;
				top: 0.125rem;
				transform: rotate(45deg);
				width: 0.125rem;
			}
		}
	}

	&-item-name {
		margin-left: 0.75rem;
	}

	&-item-actions {
		border-top: 0.0625rem solid $color-grey-85;
		display: flex;
		justify-content: flex-end;
		margin: 0 1.5rem 1.625rem;
		padding-top: 1.625rem;
	}

	&-save {
		@extend %button-primary;
		border-radius: 0.0625rem;
		padding: 0.625rem 1.75rem;
		
	}

	&-clear,
	&-save {
		font-size: 0.625rem;
		font-weight: $font-weight-medium;
		letter-spacing: 0.0362rem;
		line-height: 1.5;
	}

	&-clear {
		margin-right: 2rem;
		text-decoration: underline;
		transition: $default-transition;

		&:hover {
			color: $primary-color;
		}
	}
}

.recipe-filter-list {
	appearance: none;
	border: 0;
}
