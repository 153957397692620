.header {
	background-color: $primary-color;
	color: $color-dimgrey;
	font-family: $title-font;
	height: $mobile-navbar-height;
	padding: 0;
	position: fixed;
	text-transform: uppercase;
	top: 0;
	width: 100%;
	z-index: 1;

	@include breakpoint-up(lg) {
		height: $desktop-navbar-height;
		position: relative;
	}

	.header-searchmenu {
		display: none;
	}
}

.header-wrapper {
	border-bottom: 1px solid $primary-color;
	height: 100%;
	overflow: hidden;
	padding: 0 var(--body-margin);
	position: relative;
}

.header-navbar {
	align-items: center;
	background-color: $primary-color;
	display: flex;
	flex-direction: row;
	height: $mobile-navbar-height;
	justify-content: space-between;
	position: relative;
	width: 100%;

	@include breakpoint-up(sm) {
		position: relative;
	}

	@include breakpoint-up(lg) {
		height: $desktop-navbar-height;
		margin: 0;
		width: auto;
	}
}

.menu-toggle-button {
	align-items: center;
	background-color: transparent;
	border: 0;
	cursor: pointer;
	display: flex;
	height: 1.1875rem;
	justify-content: center;
	position: relative;
	width: 1.1875rem;

	&:focus {
		outline: none;
	}

	&-bottom-line,
	&-middle-line,
	&-top-line {
		background-color: $button-primary-color;
		border: 0;
		display: block;
		height: 0.1563rem;
		position: absolute;
		right: 0;
		transition: $default-transition;
		width: 1.1875rem;
	}

	&-bottom-line {
		top: 0.75rem;
	}

	&-middle-line {
		top: 0.375rem;
	}

	&-top-line {
		top: 0;
	}
}

.header-logo {
	height: 2.625rem;
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 9.8125rem;

	@include breakpoint-up(lg) {
		left: auto;
		position: relative;
		top: auto;
		transform: none;
	}

	&-link {
		display: block;
	}

	&-image {
		height: 100%;
		width: 100%;

		@include breakpoint-up(lg) {
			height: auto;
			width: 9.8125rem;
		}

		@include breakpoint-up(xl) {
			height: 100%;
			width: 100%;
		}
	}
}

.header-search {
	align-items: center;
	color: $button-primary-color;
	display: flex;
	flex-direction: column;

	@include breakpoint-up(lg) {
		height: 100%;
		justify-content: center;
		padding-left: 3.125rem;
	}

	&-title {
		display: none;
		font-size: 0.625rem;
		letter-spacing: 0.0519rem;
		line-height: 1.5;
		margin: 0 0 1rem;

		@include breakpoint-up(lg) {
			display: block;
		}
	}

	&-icon {
		height: 1.1875rem;
		width: 1.1875rem;

		@include breakpoint-up(lg) {
			margin-left: 0.1875rem;
		}

		&:hover {
			cursor: pointer;
			fill: $primary-color;
			transition: $default-transition;
		}
	}

	.btn-close {
		display: none;
		height: 1.75rem;
		width: 2rem;

		&::after,
		&::before {
			left: 0.9rem;
		}
	}

	&-button svg {
		fill: $button-primary-color;
		height: 1.25rem;
		width: 1.25rem;

		&:hover {
			fill: $secondary-color;
			transition: $default-transition;
		}
	}
}

.header-searchmenu {
	height: calc(100vh - #{$mobile-navbar-height});
	opacity: 0;
	padding: 4rem 0;
	position: relative;
	transition: margin-top 0s ease 0.1s, opacity 0s ease 0s;
	width: 100%;
	z-index: 0;

	input {
		border: 0;
		border-bottom: 1px solid $body-font-color;
	}

}

.header-navmenu {
	height: calc(100vh - #{$mobile-navbar-height});
	margin-top: -999rem;
	opacity: 0;
	position: relative;
	transition: margin-top 0s ease 0.1s, opacity 0s ease 0s;
	width: 100%;
	z-index: 0;

	@include breakpoint-up(lg) {
		align-items: center;
		display: flex;
		flex: 1 0 auto;
		height: 100%;
		justify-content: space-between;
		margin-top: 0;
		opacity: 1;
		width: auto;
	}

	.nav-menu {
		align-items: center;
		display: flex;
		flex-direction: column;
		list-style: none;
		margin: 0;
		padding: 4rem 0 1rem;

		@include breakpoint-up(lg) {
			display: flex;
			flex-direction: row;
			margin-left: 1.75rem;
			padding: 0;
		}

		&-item {
			margin: 1.25rem 0;

			@include breakpoint-up(lg) {
				margin: 0 1rem;
			}
		}

		.nav-link {
			color: $button-primary-color;
			font-family: $title-font;
			font-size: 1.25rem;
			font-weight: 500;
			letter-spacing: 0.0875rem;
			line-height: 1.5;
			text-decoration: none;
			text-transform: uppercase;

			@include breakpoint-up(lg) {
				font-size: 0.875rem;
				letter-spacing: 0.06rem;
				line-height: 1.4285;
			}

			&:hover {
				color: $secondary-color;
				cursor: pointer;
				transition: $default-transition;
			}
		}
	}
}

.header.search-open {
	height: auto;
	position: fixed;

	@include breakpoint-up(xl) {
		height: 100%;
		position: relative;

		&::after {
			background-color: $primary-color;
			bottom: 0;
			content: '';
			left: 0;
			position: fixed;
			right: 0;
			top: 0;
		}

		.header-search-title,
		.header-logo {
			visibility: hidden;
		}
	}

	.header-wrapper {
		overflow-y: hidden;
	}

	.header-navbar {
		z-index: 1;
	}

	.header-searchmenu {
		display: block;
		margin: 0;
		opacity: 1;
		transition: margin-top 0s ease 0s, opacity 0.1s ease 0.1s;
		z-index: 1;
	}

	.header-search-button,
	.header-navmenu {
		display: none;
	}

	.header-search-close-button {
		display: block;
	}

	.header-search-title,
	.header-menu-button {
		opacity: 0;
	}

	.header-logo {
		z-index: 2;
	}
}

.btn {
	&-close {
		cursor: pointer;
		height: 1rem;
		margin-right: auto;
		padding: 0;
		position: relative;
		transition: height $default-transition;
		width: 1rem;
	}

	&-close::before,
	&-close::after {
		background-color: $button-primary-color;
		content: ' ';
		height: 1.25rem;
		left: 0.5rem;
		position: absolute;
		top: 0;
		width: 0.125rem;
	}

	&-close::before {
		transform: rotate(45deg);
	}

	&-close::after {
		transform: rotate(-45deg);
	}
}

.header.menu-open {
	height: auto;
	position: fixed;

	.header-wrapper {
		overflow-y: scroll;
	}

	.header-searchmenu {
		display: none;
	}

	.header-navbar {
		z-index: 1;

		.menu-toggle-button {
			&-middle-line {
				display: none;
			}

			&-bottom-line,
			&-top-line {
				width: 1.625rem;
			}

			&-bottom-line {
				right: -0.125rem;
				top: 0.5rem;
				transform: rotate(-45deg);
			}

			&-top-line {
				right: -0.125rem;
				top: 0.5rem;
				transform: rotate(45deg);
			}
		}
	}

	.header-navmenu {
		margin: 0;
		opacity: 1;
		transition: margin-top 0s ease 0s, opacity 0.1s ease 0.1s;
		z-index: 1;
	}
}

.header-social {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 1rem 0 1.5rem;
	text-align: center;

	@include breakpoint-up(lg) {
		display: none;
	}

	.social-title {
		@include breakpoint-up(lg) {
			margin-bottom: 1.0625rem;
		}
	}

	.social-links-list {
		display: flex;
		justify-content: center;

		@include breakpoint-up(lg) {
			margin-bottom: 0.25rem;
		}

		.social-link {
			margin: 0 0.1875rem;
		}

		.social-icon {
			fill: $button-primary-color;

			&:hover {
				fill: $secondary-color;
			}
		}
	}
}

.header-newsletter-link {
	.newsletter-link {
		@include breakpoint-up(lg) {
			display: none;
		}

		a {
			font-size: 0.75rem;
			padding: 0.8125rem;
		}
	}
}

/**
 * Disable scroll by fixing the position and ignoring touch actions.
 */
.disable-scroll {
	&,
	body {
		height: 100%;
		position: fixed;
		touch-action: none;
	}

	body {
		overflow: hidden;
	}
}

.header-notice {
	align-items: center;
	display: flex;
	justify-content: center;
	margin: 0 var(--body-margin);
	padding-bottom: 1rem;
	padding-top: 2rem;

	@include breakpoint-up(xl) {
		margin: 0 auto;
		max-width: 66.5rem;
		padding-bottom: 1rem;
		padding-top: 0;
	}

	.notice-icon {
		display: flex;
		justify-content: center;
		margin-right: .625rem;
	}

	.notice-message {
		font-family: $title-font;
		font-size: 0.875rem;
		font-style: normal;
		font-weight: $font-weight-normal;
		letter-spacing: 0.05444rem;
		line-height: 1;

		@include breakpoint-up(xl) {
			font-size: 1rem;
		}
	}
}
