.popup {
	align-items: center;
	background: rgba(0, 0, 0, 0.7);
	display: none;
	height: 100%;
	justify-content: center;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 99999999;

	&-wrapper {
		background-color: $body-background;
		max-height: 100vh;
		min-width: 20.25rem;
		overflow: auto;
		position: relative;
		text-align: center;

		@include breakpoint-up(lg) {
			min-width: 37.5rem;
		}
	}

	&-main {
		margin: 0 auto;
		max-width: 16.875rem;
		padding: 1.25rem 0.625rem;
		text-align: center;

		@include breakpoint-up(lg) {
			max-width: 31.25rem;
		}
	}

	&-heading {
		color: $primary-color;
		font-size: 1.5625rem;
	}

	&-content {
		p {
			color: $color-grey-35;
			font-size: 0.9375rem;
			line-height: 1.5;
		}
	}

	&-close-button {
		appearance: none;
		background-color: $primary-color;
		border: 0;
		border-radius: 0;
		color: $button-primary-color;
		font-size: 0.75rem;
		font-weight: $font-weight-medium;
		height: 3rem;
		letter-spacing: 0.05rem;
		line-height: 1.4;
		margin: 1.25rem 0;
		min-width: 7.0625rem;
		text-align: center;
		text-transform: uppercase;
	}

	&-active {
		overflow: hidden;

		.popup {
			display: flex;
		}
	}
}
