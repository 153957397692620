.page-where-to-watch {
	top: 4.5rem !important;

	@include breakpoint-up(lg) {
		top: 0 !important;
	}
}

.banner {
	align-items: center;
	background-image: url('/images/where-to-watch-header.jpg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	justify-content: center;
	position: relative;

	@include breakpoint-up(xs) {
		height: 8.125rem;
		margin-bottom: 2.1875rem;
	}

	@include breakpoint-up(md) {
		height: 17.75rem;
		margin-bottom: 3.125rem;
	}

	.title img {

		height: 2.9375rem;

		@include breakpoint-up(md) {
			height: 5.625rem;
		}
	}
}

.where-to-watch {
	display: flex;
	flex-direction: column;
	padding: 0 var(--body-margin) 0.75rem;

	h2 {
		font-family: $title-font;
		font-size: 1.375rem;
		font-weight: $font-weight-medium;
		letter-spacing: 0.1rem;
		margin-bottom: 3.4375rem;
	}

	h3 {
		font-family: $title-font;
		font-weight: $font-weight-medium;
		letter-spacing: 0.09rem;
		margin-bottom: 2.3125rem;
		margin-top: 0;
		text-transform: uppercase;

		@include breakpoint-up(xs) {
			font-size: 1.125rem;
		}

		@include breakpoint-up(sm) {
			font-size: 1.375rem;
		}
	}

	.where-to-watch {
		img {
			width: 100%;
		}

		&__channel_single {
			display: flex;
			flex-direction: row;
			margin-bottom: 3.875rem;
		}

		.channel-logo {
			@include breakpoint-up(xs) {
				width: 8.75rem;
			}

			@include breakpoint-up(sm) {
				width: 12.5rem;
			}
		}

		.channel-info {
			margin-left: 0.5rem;

			word-wrap: break-word;

			@include breakpoint-up(xs) {
				max-width: 52%;
			}

			@include breakpoint-up(sm) {
				margin-left: 1rem;
			}

			@include breakpoint-up(md) {
				max-width: 55%;
			}
		}



		.channel-title {
			color: $color-dimgrey;
			font-family: $title-font;
			font-size: 0.68rem;
			font-weight: $font-weight-medium;
			letter-spacing: 0.04rem;
			margin-bottom: 0.4375rem;
			text-transform: uppercase;
		}

		.channel-watch-now-button-container {
			margin: 2rem 0;

			@include breakpoint-up(sm) {
				margin: 2.1875rem 0;
			}

			@include breakpoint-up(lg) {
				margin: 1.5rem 0;
			}
		}

		.channel-watch-now-button {
			@extend %load-more;
			padding: 0.8rem;
		}
	}

	.channel-desc {
		line-height: 1.44;

		> p {
			margin: 0;
		}

		a {
			text-decoration: none;
			@include add-underline($primary-color);
		}
	}

	.channel-listings {
		p {
			letter-spacing: 0.002rem;
			line-height: 1.5;
			margin: 0;
		}
	}
}
