/* stylelint-disable selector-class-pattern */
.react-share__ShareButton {
	background-color: transparent;
	border: 0;
	color: inherit;
	cursor: pointer;
	font: inherit;
	height: 1.375rem;
	padding: 0;
	width: 1.375rem;
}

.social-link,
.react-share__ShareButton {
	display: inline-block;
	height: 3rem;
	padding: 0.75rem;
	width: 3rem;
}

.social-icon {
	height: 1.5rem;
	margin: 0 auto;
	width: 1.5rem;

	&:hover {
		fill: $primary-color;
		transition: $default-transition;
	}
}

.social-share {
	.react-share__ShareButton {
		margin: 0 0.625rem;
	}
}

.social-title {
	color: $button-primary-color;
	font-family: $title-font;
	font-size: 0.75rem;
	font-weight: $font-weight-medium;
	letter-spacing: 0.0862rem;
	line-height: 1.5;
	margin: 1rem 0;
	text-transform: uppercase;

	@include breakpoint-up(xl) {
		font-size: 0.625rem;
		letter-spacing: 0.0519rem;
		line-height: 1.5;
		margin: 0 0 1.375rem;
	}
}

.social-links {
	height: auto;
	line-height: 1;
	margin: 0.5rem 0 0;

	@include breakpoint-up(xl) {
		margin: 0;
	}
}

.social-link {
	margin: 0 0.75rem;

	@include breakpoint-up(xl) {
		height: 1.125rem;
		margin: 0 1.4375rem;
		padding: 0;
		width: 1.125rem;

		.social-icon {
			fill: $button-primary-color;
			height: 1.125rem;
			margin: 0;
			width: 1.125rem;

			&:hover {
				fill: $secondary-color;
			}
		}
	}
}

.social-links-list {
	list-style: none;
	margin: 0;
	padding: 0;
}
