.all-shows {
	background-image: linear-gradient($body-background 0%, $related-content-background-color 20%);

	h1 {
		color: $matte-black;
		font-family: $accent-font;
		font-size: 1.625rem;
		font-weight: $font-weight-medium;
		letter-spacing: 0.1119rem;
		line-height: 1.4615;
		margin: 2.9375rem 0 0;
		padding: 0;
		text-align: center;
		text-transform: uppercase;

		@include breakpoint-up(xl) {
			font-size: 2.5rem;
			letter-spacing: 0.1719rem;
			line-height: 1.45;
			margin: 7.375rem 0 0;
		}
	}

	&-header {
		background-image: linear-gradient($body-background 0%, $body-background 50%, $color-amour 100%);
		padding: 0 var(--body-margin) 1.25rem;

		@include breakpoint-up(lg) {
			padding-bottom: 1rem;
		}
	}

	&-block {
		background-color: $color-amour;
		display: flex;
		flex-direction: column;
		padding-bottom: 2.5rem;

		@include breakpoint-up(lg) {
			padding-bottom: 3.375rem;
		}

		.ad-wrapper {
			margin: 0 0 2.8125rem;
			padding: 1.375rem 0;

			@include breakpoint-up(md) {
				margin: 0;
			}

			@include breakpoint-up(xl) {
				margin: 3.125rem 0 6.25rem;
				padding: 0;
			}
		}
	}

	.post-block {
		&__tag {
			visibility: hidden;

			@include breakpoint-up(md) {
				visibility: visible;
			}
		}
	}

	&-group {
		padding-bottom: 1.875rem;
		@include breakpoint-up(lg) {
			font-size: 2.5rem;
			padding-bottom: 4.375rem;
		}
	}

	&-section-title {
		color: $primary-color;
		font-size: 1.5rem;
		letter-spacing: .0313rem;
		line-height: 0.7916;
		padding: 0 var(--body-margin);

		@include breakpoint-up(lg) {
			font-size: 2.5rem;
		}
	}
}
