.footer {
	background-color: $primary-color;
	border-top: 0.063rem solid $primary-color;
	flex: 0 0 auto;
	justify-self: flex-end;
}

.footer-top {
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	max-width: 25rem;
	padding: 1.5rem 0 0;
	text-align: center;

	@include breakpoint-up(md) {
		max-width: 40rem;
		padding: 2.75rem 0 0;
	}

	@include breakpoint-up(xl) {
		flex-direction: row;
		margin: 0 var(--body-margin);
		max-width: none;
		padding: 3.5rem 0 0;
	}

	&-header {
		margin: 0 0 2.5rem;
		padding: 0;

		@include breakpoint-up(xl) {
			margin: 0;
		}
	}

	.footer-logo-link {
		display: block;
	}

	.social-title {
		margin: 1.625rem 0 0.25rem;

		@include breakpoint-up(md) {
			margin: 2rem 0 0.25rem;
		}

		@include breakpoint-up(xl) {
			margin: 0 0 0.875rem;
		}
	}

	.social-links {
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: center;

		.social-link {
			margin: 0 0.4rem;

			@include breakpoint-up(lg) {
				margin: 0 0.75rem;
			}
		}
	}

	// Override Social Icons
	.social-link {
		transform: scale(0.85);	// Transform icon to 22px x 22px

		.social-icon {
			fill: $button-primary-color;

			&:hover {
				fill: $secondary-color;
			}
		}
	}
}

.footer-right {
	display: flex;
	flex-direction: column;
}

.footer-logo-image {
	height: 2.625rem;
	width: 9.8125rem;

	@include breakpoint-up(xl) {
		margin: 1rem;
	}
}

/**
 * Use CSS Grid for the footer menu layout.
 * On mobile, use 2 columns with 2/3rds and 1/3rd width.
 * On tablet and up, use 3 equal columns.
 */
.footer-menu {
	display: grid;
	grid-template-columns: 2fr 1fr;
	// Align the menu items to the left-side of the cell.
	// Since the items are just links, this looks like applying `text-align: left;` to each link.
	height: 11rem;
	justify-items: start;
	margin: 0;
	padding: 0 2rem;

	@include breakpoint-up(md) {
		grid-template-columns: repeat(3, 1fr);
		height: 8rem;
		padding: 0;
	}

	@include breakpoint-up(xl) {
		padding: 0 0 0 10rem;
		width: auto;
	}

	.menu-item {
		line-height: 1.5;
		list-style: none;
		max-width: 10rem;
		text-align: left;

		@include breakpoint-between(md, xl) {
			max-width: 18rem;
			padding-left: 1rem;
		}

		@include breakpoint-up(xl) {
			line-height: 1.3;
			max-width: none;
			padding: 0.5rem;
			width: 18rem;
		}
	}

	.nav-link {
		// `display: inline-block;` facilitates the width trick below.
		color: $button-primary-color;
		display: inline-block;
		font-family: $title-font;
		font-size: 0.75rem;
		font-weight: $font-weight-semi-bold;
		letter-spacing: 0.025rem;
		padding: 0;
		text-decoration: underline;
		text-decoration-color: transparent;
		text-decoration-thickness: 0.0938rem;
		text-transform: uppercase;
		transition: $default-transition;
		// This is a neat little trick that has the effect of overflowing the link's text
		// into the next line if it is longer than this many characters.
		width: 16ch;

		@include breakpoint-up(md) {
			width: 20ch;
		}

		&:hover {
			text-decoration-color: $secondary-color;
		}
	}
}

.footer-bottom {
	background-color: $footer-background-color;
	padding: 1.875rem 0;
	text-align: center;
	width: 100%;

	@include breakpoint-up(xl) {
		padding: 3.75rem 0;
	}

	.container {
		display: flex;
		flex-direction: column;
		margin: 0 var(--body-margin);
	}

	.logo-menu {
		align-items: center;
		display: flex;
		flex-direction: column;
		margin: 0 auto;

		@include breakpoint-up(md) {
			flex-direction: row;
			max-width: 45rem;
		}

		@include breakpoint-up(xl) {
			max-width: none;
		}
	}

	.menu-item {
		display: inline-block;
		padding: 0;
		width: 45%;

		@include breakpoint-up(md) {
			width: 8rem;
		}

		@include breakpoint-up(xl) {
			width: 6.5rem;
		}

		&.complex .nav-link {
			background-image: $logo-complex;
			width: 2rem;
		}

		&.corus .nav-link {
			background-image: $logo-corus;
			margin-bottom: 0.5rem;
			width: 5rem;
		}

		&.global-news .nav-link {
			background-image: $logo-globalnews;
			width: 3.5rem;
		}

		&.global-tv .nav-link {
			background-image: $logo-globaltv;
			width: 4.25rem;
		}

		&.hgtv .nav-link {
			background-image: $logo-hgtv;
		}

		&.historia .nav-link {
			background-image: $logo-historia;
		}

		&.history .nav-link {
			background-image: $logo-history;
			width: 3rem;
		}

		&.home-network .nav-link {
			background-image: $logo-home-network;
		}

		&.showcase .nav-link {
			background-image: $logo-showcase;
			width: 2.813rem;
		}

		&.slice .nav-link {
			background-image: $logo-slice;
			width: 3.75rem;
		}

		&.stacktv .nav-link {
			background-image: $logo-stacktv;
			scale: 1.4;
		}

		&.w-network .nav-link {
			background-image: $logo-wnetwork;
			width: 2.375rem;
		}
	}

	.brand {
		display: inline-block;
		padding: 0;
		vertical-align: bottom;

		@include breakpoint-up(md) {
			padding-right: 3.5rem;
			width: 12rem;
		}

		@include breakpoint-up(xl) {
			padding-right: 0;
		}
	}

	.nav-link {
		@include focus-outline($body-background);
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		display: inline-block;
		height: 3.75rem;
		text-decoration: none;
		vertical-align: bottom;
		width: 4rem;

		&:focus {
			opacity: 1;
		}

		&:focus:not(:focus-visible) {
			opacity: 0.8;
		}

		&:focus-visible {
			opacity: 1;
		}
	}
}

.footer-bottom-logo-menu {
	display: block;
}

.footer-bottom-logos {
	border: 0;
	margin: 0 auto;
	max-width: 15rem;
	padding: 0;

	@include breakpoint-up(md) {
		border-left: 0.0625rem solid $color-dimgrey;
		display: inline-block;
		margin: 0;
		max-width: none;
	}

	li:first-child {
		@include breakpoint-up(xl) {
			margin-left: 1rem;
		}
	}

	.nav-link {
		margin: 0 auto 0.5rem;
		opacity: 0.5;

		&:hover {
			opacity: 0.8;
			text-shadow: 0 0.063rem 0 rgba(#000, 0.4);
		}
	}
}

.footer-copyright {
	margin: 2rem auto 1rem;
	padding: 0 2rem;

	@include breakpoint-up(xl) {
		margin: 2.5rem auto;
	}

	p {
		color: $button-primary-color;
		font-family: $title-font;
		font-size: 0.75rem;
		font-weight: $font-weight-medium;
		letter-spacing: 0.022rem;
		line-height: 1.5;
		text-align: center;

		@include breakpoint-up(xl) {
			margin-top: 3.75rem;
		}
	}
}
